import {getInstance} from "@/helpers/axios.service";
import {endPoint} from "@/helpers/Endpoint";

export const cacheService = {
    clearCache
};

async function clearCache(request = {}) {
    return await getInstance(endPoint.cacheClear, request);
}
