<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config.json";

import "prismjs";
import "prismjs/themes/prism.css";
import {cacheService} from "@/helpers/blogservice/cache.service";

export default {
  page: {
    title: "Basic",
    meta: [{ name: "description", content: appConfig.description }],
  },
  mounted() {
  },
  data() {
    return {
      title: "Cache manager",
      items: [
        {
          text: "Setting",
          href: "/",
        },
        {
          text: "Cache",
          active: true,
        },
      ],
      siteDomain: process.env.VUE_APP_SITE_URL,
      models: []
    };
  },
  methods: {
    async onClear() {
      await cacheService.clearCache();
    },
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Cache</h4>
            <div class="flex-shrink-0 hstack gap-2">
              <button type="button" class="btn btn-info mr-5" @click="onClear">
                <i class="las la-search"></i> Clear
              </button>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <div class="live-preview">



            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!--end row-->
  </Layout>
</template>
